import React from "react";
import "./components.css";

const Footer = () => {
  return (
    <div className="bg-primary1">
      <div className="py-10 sm:px-10 px-4">
        <div className="sm:grid lg:grid-cols-4 text-white ">
          <div className="col-span-1 flex sm:justify-center justify-start">
            <div>
              <span className="text-primary3 sm:text-32 text-24 font-medium font-clash-display">
                Quick Links
              </span>
              <div>
                <ul className="text-white sm:text-18 text-14 font-medium font-clash-display">
                  <li className="py-2">About Us</li>
                  <li className="py-2">Destination</li>
                  <li className="py-2">Contact Us</li>
                </ul>
              </div>
            </div>
          </div>
          <div className="col-span-1 flex sm:justify-center justify-start">
            <div>
              <span className="text-primary3 sm:text-32 text-24 font-medium font-clash-display">
                Terms & Policies
              </span>
              <div>
                <ul className="text-white sm:text-18 text-14 font-medium font-clash-display">
                  <li className="py-2">Terms & Condition</li>
                  <li className="py-2">Privacy Policy</li>
                  <li className="py-2">Cancellation Policy</li>
                </ul>
              </div>
            </div>
          </div>
          <div className="col-span-2 flex sm:justify-center justify-start lg:mt-0 mt-10">
            <div>
              <span className="text-primary3 sm:text-32 text-24 font-medium font-clash-display">
                Subscribe Us
              </span>
              <div className="flex w-[100%] bg-[#FFFFFF33] py-2 ps-5 pe-2 rounded-xl">
                <input
                  className="subcribe_input border-none outline-none sm:text-18 text-14 text-white font-medium font-clash-display pe-5 w-[100%]"
                  placeholder="Email Address"
                />
                <button
                  type="button"
                  className="text-primary1 bg-primary2 sm:text-24 text-18 font-medium font-clash-display rounded-xl py-2 sm:px-5 px-2 whitespace-nowrap"
                >
                  Subscribe Us
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <hr className="border-t-primary2" />
      <div className="flex justify-center text-center py-4">
        <span className="text-primary3 md:text-32 sm:text-26 text-20 font-medium font-clash-display">
          ©2024 Nature Signts. {" "}All rights Reserved
        </span>
      </div>
    </div>
  );
};

export default Footer;
