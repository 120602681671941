import React from "react";
import "./components.css";
import { PlaceData } from "../data";
import Carousel from "./carousel";

const PopularDestination = () => {
  return (
    <div className="flex justify-center xl:px-[200px] lg:px-[70px] md:px-[40px] px-0 text-center">
      <div>
        <div>
          <span className="sm:text-48 text-36 font-bold font-clash-display text-secondary1 md:py-[100px] py-[40px]">
            Popular Destinations
          </span>
        </div>
        <div className="md:px-[100px] sm:px-8">
          <span className="text-14 font-satoshi font-normal pt-5 text-neutral3 ">
            Lorem ipsum dolor sit amet consectetur. Suspendisse enim elit
            dapibus neque turpis lectus mauris magna. Nisl sit in eget
            venenatis. Neque eros tempus tincidunt viverra.
          </span>
        </div>

        <div>
          <Carousel data={PlaceData} />
        </div>
      </div>
    </div>
  );
};

export default PopularDestination;
