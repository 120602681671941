import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

import React from "react";
import HomePage from "../pages/homePage";
import PlaceInformation from "../pages/placeInformation";


const Index = () => {
  return (
    <>
      <Router>
        <Routes>
          
          <Route path="/" element={<HomePage />} />
          <Route path="/place-info/:name" element={<PlaceInformation />} />
        </Routes>
      </Router>
    </>
  );
};

export default Index;
