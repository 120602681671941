import React, { useState } from "react";
import "./components.css";
import { useNavigate } from "react-router-dom";

const Carousel = ({ data }) => {
  const navigate = useNavigate();
  const [currentIndex, setCurrentIndex] = useState(0);

  const handlePrev = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === 0 ? data.length - 1 : prevIndex - 1
    );
  };

  const handleNext = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === data.length - 1 ? 0 : prevIndex + 1
    );
  };

  const prevIndex = currentIndex === 0 ? data.length - 1 : currentIndex - 1;
  const nextIndex = currentIndex === data.length - 1 ? 0 : currentIndex + 1;

  return (
    <div className="flex justify-center sm:py-[100px] py-8">
      <div className="flex md:gap-[4rem] sm:gap-[2rem] gap-2">
        <div className="flex gap-6 md:pt-0 pt-10 justify-between">
          <button onClick={handlePrev}>
            <i class="ri-arrow-left-line bg-primary2 sm:p-4 p-2 rounded-xl" />
          </button>
        </div>
        <div className="flex items-center lg:relative">
          <div className="bg-white rounded-[30px] shadow-xl pb-5 lg:block hidden">
            <div className="w-[380px] h-[350px]">
              <img
                src={data[prevIndex].image}
                alt={data[prevIndex].place}
                style={{
                  width: "100%",
                  height: "100%",
                  filter: "brightness(40%) contrast(120%)",
                  objectFit: "cover",
                  transition: "filter 0.5s ease",
                }}
                className="opacity-75 rounded-[30px]"
              />
            </div>

            <div className="text-start px-4 pt-3">
              <span className="lg:text-36 text-20 font-bold font-satoshi text-neutral1">
                {data[prevIndex].place}
              </span>
            </div>
            <div className="text-start px-4">
              <span className="lg:text-18 text-14 font-satoshi font-bold text-primary1">
                Starting From ₹{data[prevIndex].startingPrice}
              </span>
            </div>
          </div>
          <div
            className="bg-white rounded-[30px] shadow-xl z-[99] lg:absolute lg:top-1/2 lg:left-1/2 lg:transform lg:-translate-x-1/2 lg:-translate-y-1/2 pb-5"
            onClick={() => {navigate(`/place-info/${data[currentIndex].place}`)}}
          >
            <div className="sm:w-[430px] sm:h-[400px] w-[250px] h-[250px]">
              <img
                src={data[currentIndex].image}
                alt={data[currentIndex].place}
                style={{
                  width: "100%",
                  height: "100%",
                  filter: "brightness(40%) contrast(120%)",
                  objectFit: "cover",
                  transition: "filter 0.5s ease",
                }}
                className="opacity-75 rounded-[30px]"
              />
            </div>
            <div className="text-start px-4 pt-3">
              <span className="lg:text-48 text-24 font-bold font-satoshi text-neutral1">
                {data[currentIndex].place}
              </span>
            </div>
            <div className="text-start px-4">
              <span className="lg:text-24 text-18 font-satoshi font-bold text-primary1">
                Starting From ₹{data[currentIndex].startingPrice}
              </span>
            </div>
          </div>
          <div className="bg-white rounded-[30px] shadow-xl pb-5 lg:block hidden">
            <div className="w-[380px] h-[350px]">
              <img
                src={data[nextIndex].image}
                alt={data[nextIndex].place}
                style={{
                  width: "100%",
                  height: "100%",
                  filter: "brightness(40%) contrast(120%)",
                  objectFit: "cover",
                  transition: "filter 0.5s ease",
                }}
                className="opacity-75 rounded-[30px]"
              />
            </div>
            <div className="text-start px-4 pt-3">
              <span className="lg:text-36 text-20 font-bold font-satoshi text-neutral1">
                {data[nextIndex].place}
              </span>
            </div>
            <div className="text-start px-4">
              <span className="lg:text-18 text-14 font-satoshi font-bold text-primary1">
                Starting From ₹{data[nextIndex].startingPrice}
              </span>
            </div>
          </div>
        </div>

        <div className="flex gap-6 md:pt-0 pt-10 justify-between">
          <button onClick={handleNext} className="">
            <i class="ri-arrow-right-line bg-primary2 sm:p-4 p-2 rounded-xl" />
          </button>
        </div>
      </div>
    </div>
  );
};

export default Carousel;
