import Index from "./routes";
import "remixicon/fonts/remixicon.css";

function App() {
  return (
    <>
      <Index />
    </>
  );
}

export default App;
