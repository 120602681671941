import React, { useEffect, useRef, useState } from "react";
import "./homePage.css";
import AboutUs from "../components/aboutUs";
import PopularDestination from "../components/popularDestination";
import { PlaceData, phoneNumber } from "../data";
import { useLocation, useNavigate } from "react-router-dom";
import ThinkYouNeedTo from "../components/thinkYouNeedTo";
import Footer from "../components/footer";
import Marquee from "react-fast-marquee";

const HomePage = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const [show, setShow] = useState(false);

  const aboutUsRef = useRef(null);
  const destinationRef = useRef(null);
  const contactUsRef = useRef(null);

  const scrollToSection = (ref) => {
    ref.current.scrollIntoView({ behavior: "smooth" });
  };

  useEffect(() => {
    if (!location.state) {
      window.scrollTo(0, 0);
    } else if (location.state === "aboutUs") {
      scrollToSection(aboutUsRef);
    } else if (location.state === "destination") {
      scrollToSection(destinationRef);
    } else if (location.state === "contactUs") {
      scrollToSection(contactUsRef);
    }
  }, [location.state]);

  const [currentIndex, setCurrentIndex] = useState(0);

  const handleNext = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === PlaceData.length - 1 ? 0 : prevIndex + 1
    );
  };

  const handlePrev = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === 0 ? PlaceData.length - 1 : prevIndex - 1
    );
  };

  const handleCallClick = () => {
    if (isMobileDevice()) {
      window.open(`tel:${phoneNumber}`);
    } else {
      alert(`Please call us at ${phoneNumber}`);
    }
  };

  const isMobileDevice = () => {
    return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
      navigator.userAgent
    );
  };

  return (
    <div>
      <div className="w-[100%] flex justify-between items-center lg:px-12 px-5 py-5 font-bold bg-inherit fixed top-0 z-[999] backdrop-blur-lg">
        <div className="md:text-36 text-24 invert-0 font-clash-display">
          <button type="button" onClick={() => window.scrollTo(0, 0)}>
            Nature Sight
          </button>
        </div>
        <div className="lg:visible hidden lg:flex items-center gap-10 text-18 font-medium font-clash-display invert-0 ">
          <button type="button" onClick={() => scrollToSection(aboutUsRef)}>
            About Us
          </button>
          <button type="button" onClick={() => scrollToSection(destinationRef)}>
            Destinations
          </button>
          <button type="button" onClick={() => scrollToSection(contactUsRef)}>
            Contact US
          </button>
          <button
            type="button"
            className="font-satoshi font-18 font-bold rounded-2xl px-8 py-2 bg-neutral1 text-primary2"
            onClick={() => {
              handleCallClick();
            }}
          >
            Call Now
          </button>
        </div>

        <div className="lg:hidden visible">
          <button
            className="text-neutral1 text-32"
            type="button"
            onClick={() => {
              setShow((prev) => !prev);
            }}
          >
            <i className="ri-menu-3-line" />
          </button>
        </div>
        {show && (
          <div className="lg:hidden visible absolute top-0 left-0 bg-secondary1 h-[100vh] w-[100vw]">
            <div className="flex justify-end absolute top-4 right-4">
              <button
                className="text-32 text-neutral1"
                type="button"
                onClick={() => {
                  setShow((prev) => !prev);
                }}
              >
                <i className="ri-close-fill" />
              </button>
            </div>
            <div className="flex justify-center items-center flex-col gap-10 absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2">
              <button
                type="button"
                onClick={() => {
                  scrollToSection(aboutUsRef);
                  setShow(false);
                }}
              >
                About Us
              </button>
              <button
                type="button"
                onClick={() => {
                  scrollToSection(destinationRef);
                  setShow(false);
                }}
              >
                Destinations
              </button>
              <button
                type="button"
                onClick={() => {
                  scrollToSection(contactUsRef);
                  setShow(false);
                }}
              >
                Contact US
              </button>
              <button
                type="button"
                className="font-satoshi font-18 font-bold rounded-2xl px-8 py-2 bg-neutral1 text-primary2"
                onClick={() => {
                  handleCallClick();
                }}
              >
                Call Now
              </button>
            </div>
          </div>
        )}
      </div>
      <div className="">
        <div style={{ position: "relative", width: "100%", height: "700px" }}>
          <img
            src={PlaceData[currentIndex].image}
            alt="Your Image"
            style={{
              width: "100%",
              height: "100%",
              filter: "brightness(40%) contrast(120%)",
              objectFit: "cover",
              transition: "filter 0.5s ease",
            }}
            className="opacity-75"
          />
          <div
            style={{
              position: "absolute",
              top: "0%",
              width: "100%",
            }}
          >
            <div className="absolute sm:pt-[200px] pt-[120px] flex md:px-10">
              <Marquee>
                <span className="text-18 px-10">
                  At Nature Sight Seeing, we offer a comprehensive range of
                services to ensure a seamless and enjoyable travel experience.
                Enjoy free pickup and drop-off from airports, train stations,
                and bus stands, making your journey stress-free from start to
                finish. Choose from a variety of accommodation options including
                budget-friendly hostels, luxurious villas, and cozy lodges, all
                equipped with modern amenities. Enhance your trip with our city
                tours, adventure activities, and curated dining experiences that
                let you savor the local cuisine. Plus, our 24/7 customer support
                is always available to assist you with any queries or concerns.
                Let us handle the details while you focus on making
                unforgettable memories.For inquiries, please contact us at <span className="text-20 font-semibold">{phoneNumber}</span>.
                </span>
                
              </Marquee>
            </div>

            <div className="sm:pt-[350px] pt-[270px] flex">
              <div className="lg:ps-[100px] px-5 md:basis-1/2">
                <div className=" text-white md:text-48 text-26 font-bold font-satoshi ">
                  <span className="border-l-[8px] border-white  ps-4 pe-10 py-3 city_name_text">
                    {PlaceData[currentIndex].place}
                  </span>
                </div>
                <div className="xl:pe-[7rem] py-4">
                  <span
                    className="sm:text-18 text-16 font-normal font-satoshi text-white"
                    title={PlaceData[currentIndex].description}
                  >
                    {PlaceData[currentIndex].description?.substring(0, 250)}
                    {PlaceData[currentIndex].description?.length > 250 && "..."}
                  </span>
                </div>
              </div>
            </div>
            <div className="lg:px-[100px] px-5 md:flex justify-between">
              <div className="flex gap-4">
                <button
                  className="font-satoshi font-18 font-bold rounded-2xl px-8 py-2 text-neutral1 bg-primary2"
                  type="button"
                  onClick={() => {
                    handleCallClick();
                  }}
                >
                  Call Now
                </button>
                <button
                  className="font-satoshi font-18 font-bold rounded-2xl px-12 py-2 text-white border-[2px]"
                  onClick={() => {
                    navigate(`/place-info/${PlaceData[currentIndex].place}`);
                  }}
                >
                  View
                </button>
              </div>
              <div className="flex gap-6 md:pt-0 pt-10 justify-between">
                <button onClick={handlePrev}>
                  <i className="ri-arrow-left-line text-24 p-2 border-2 rounded-xl border-white text-white" />
                </button>
                <button onClick={handleNext}>
                  <i className="ri-arrow-right-line text-24 p-2 border-2 rounded-xl border-white text-white" />
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <ThinkYouNeedTo />

      <div ref={aboutUsRef}>
        <AboutUs />
      </div>

      <div ref={destinationRef}>
        <PopularDestination />
      </div>

      <div className="mt-10" ref={contactUsRef}>
        <Footer />
      </div>
    </div>
  );
};

export default HomePage;
