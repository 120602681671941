import React from "react";
import AboutUsImage from "../assest/images/aboutUs.png";
import "./components.css";

const AboutUs = () => {
  return (
    <div className="grid md:grid-cols-2 grid-cols-1 xl:px-[150px] lg:px-[70px] md:px-[40px] md:py-[100px] py-[40px]">
      <div className="px-10 sm:px-[4rem]">
        <img className="w-[100%]" src={AboutUsImage} alt="Water Drop Effect" />
      </div>
      <div className="flex flex-col">
        <span className="text-primary1 sm:text-48 text-36 font-bold font-clash-display md:px-0 px-5 sm:pt-0 pt-5">
          About Us
        </span>
        <span className="text-18 text-neutral1 font-normal font-satoshi md:px-0 px-5">
          At Nature Sight Seeing, we're passionate about travel and dedicated to
          helping you create unforgettable experiences. With years of expertise
          in the travel industry, our team is committed to providing
          personalized service and expert guidance to make your journey seamless
          and memorable. From dreamy destinations to off-the-beaten-path
          adventures, we're here to turn your travel dreams into reality.
          Discover the world with confidence and let us be your trusted partner
          every step of the way.
        </span>
      </div>
    </div>
  );
};

export default AboutUs;
