import React, { useEffect } from "react";
import "./homePage.css";
import MysuruImage from "../assest/images/mysorePlace.jpg";
import { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { PlaceData, phoneNumber } from "../data";
import ArrowIcon from "../assest/icons/arrowIcon.svg";
import CallMeIcon from "../assest/icons/callMeIcon.png";
import PlaceCard from "../components/placeCards";
import carImage from "../assest/icons/miniIcon.svg";
import PopularDestination from "../components/popularDestination";
import ThinkYouNeedTo from "../components/thinkYouNeedTo";
import Footer from "../components/footer";

const PlaceInformation = () => {
  const param = useParams();
  const navigate = useNavigate();
  const placeName = param?.name;

  const [placesData, setPlacesData] = useState([]);
  const [isPricingShow, setIsPricingShow] = useState(false);
  const [show, setShow] = useState(false);

  useEffect(() => {
    const data = PlaceData?.find((item) => item.place === placeName);
    setPlacesData(data);
    window.scrollTo(0, 0);
  }, [placeName]);

  const handleCallClick = () => {
    if (isMobileDevice()) {
      window.open(`tel:${phoneNumber}`);
    } else {
      alert(`Please call us at ${phoneNumber}`);
    }
  };

  const isMobileDevice = () => {
    return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
      navigator.userAgent
    );
  };

  return (
    <>
      <div className="w-[100%] flex justify-between items-center lg:px-12 px-5 py-5 font-bold bg-inherit fixed top-0 z-[999] backdrop-blur-lg">
        <div className="md:text-36 text-24 text-neutral1 font-clash-display">
          <button type="button" onClick={() => navigate("/")}>
            Nature Sight
          </button>
        </div>
        <div className="lg:visible hidden lg:flex items-center gap-10 text-18 font-medium font-clash-display text-neutral1">
          <button
            type="button"
            onClick={() => navigate("/", { state: "aboutUs" })}
          >
            About Us
          </button>
          <button
            type="button"
            onClick={() => navigate("/", { state: "destination" })}
          >
            Destinations
          </button>
          {/* <button>Reviews</button> */}
          <button
            type="button"
            onClick={() => navigate("/", { state: "contactUs" })}
          >
            Contact US
          </button>
          <button
            className="font-satoshi font-18 font-bold rounded-2xl px-8 py-2 text-neutral1 bg-primary2"
            type="button"
            onClick={() => handleCallClick()}
          >
            Call Now
          </button>
        </div>
        <div className="lg:hidden visible">
          <button
            className="text-neutral1 text-32"
            type="button"
            onClick={() => {
              setShow((prev) => !prev);
            }}
          >
            <i className="ri-menu-3-line" />
          </button>
        </div>
        {show && (
          <div className="lg:hidden visible absolute top-0 left-0 bg-secondary1 h-[100vh] w-[100vw]">
            <div className="flex justify-end absolute top-4 right-4">
              <button
                className="text-32 text-neutral1"
                type="button"
                onClick={() => {
                  setShow((prev) => !prev);
                }}
              >
                <i className="ri-close-fill" />
              </button>
            </div>
            <div className="flex justify-center items-center flex-col gap-10 absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2">
              <button
                type="button"
                onClick={() => {
                  navigate("/", { state: "aboutUs" });
                  setShow(false);
                }}
              >
                About Us
              </button>
              <button
                type="button"
                onClick={() => {
                  navigate("/", { state: "destination" });
                  setShow(false);
                }}
              >
                Destinations
              </button>
              {/* <button>Reviews</button> */}
              <button
                type="button"
                onClick={() => {
                  navigate("/", { state: "contactUs" });
                  setShow(false);
                }}
              >
                Contact US
              </button>
              <button
                className="font-satoshi font-18 font-bold rounded-2xl px-8 py-2 text-neutral1 bg-primary2"
                type="button"
                onClick={() => handleCallClick()}
              >
                Call Now
              </button>
            </div>
          </div>
        )}
      </div>

      <div className="mt-[7rem] md:px-[5rem] sm:px-4 px-2">
        <div className="lg:grid lg:grid-cols-2 gap-4 items-center">
          <div className="flex justify-center">
            <div className="relative">
              <div className="flex gap-[20px]">
                <div className="lg:w-[200px] lg:h-[200px] w-[150px] h-[150px]">
                  <img
                    src={placesData?.dataTrips?.[0]?.image}
                    style={{
                      width: "100%",
                      height: "100%",
                      filter: "brightness(40%) contrast(120%)",
                      objectFit: "cover",
                      transition: "filter 0.5s ease",
                    }}
                    className="rounded-[20px]"
                  />
                </div>
                <div className="lg:w-[200px] lg:h-[200px] w-[150px] h-[150px] mt-[75px] lg:mt-[100px]">
                  <img
                    src={placesData?.dataTrips?.[1]?.image}
                    style={{
                      width: "100%",
                      height: "100%",
                      filter: "brightness(40%) contrast(120%)",
                      objectFit: "cover",
                      transition: "filter 0.5s ease",
                    }}
                    className="rounded-[20px]"
                  />
                </div>
              </div>
              <div className="lg:w-[200px] lg:h-[200px] absolute lg:top-[220px] w-[150px] h-[150px] top-[170px]">
                <img
                  src={placesData?.dataTrips?.[2]?.image}
                  style={{
                    width: "100%",
                    height: "100%",
                    filter: "brightness(40%) contrast(120%)",
                    objectFit: "cover",
                    transition: "filter 0.5s ease",
                  }}
                  className="rounded-[20px]"
                />
              </div>
            </div>
          </div>

          <div className="lg:mt-0 mt-[150px] sm:p-0 px-3">
            <div>
              <span className="font-bold font-satoshi sm:text-48 text-36  text-neutral1">
                {placesData?.place}
              </span>
            </div>
            <div>
              <span className="sm:text-24 font-normal font-satoshi text-neutral1">
                {placesData?.description}
              </span>
            </div>
            <div
              className="flex items-center pt-3"
              onClick={() => handleCallClick()}
            >
              <img src={ArrowIcon} />
              <img src={CallMeIcon} width={100} height={100} />
            </div>
          </div>
        </div>
      </div>

      <div className="sm:mt-[180px] mt-[50px]">
        <div className="bg-neutral1 relative">
          <div className="md:grid md:grid-cols-3">
            <div className="col-span-1 px-10 py-5">
              <span className="text-primary3 text-24 font-clash-display font-medium">
                Pricing
              </span>
              <div>
                <span className="text-neutral3 text-14 font-normal font-satoshi">
                  Lorem ipsum dolor sit amet consectetur. Integer a ullamcorper
                  duis scelerisque sed gravida non ac.
                </span>
              </div>
            </div>
          </div>
          <div className="absolute top-[-40px] right-[2.5rem]">
            <button
              className="bg-primary2 px-5 py-4 rounded-[25px] border-4 border-neutral1"
              type="button"
              onClick={() => setIsPricingShow((prev) => !prev)}
            >
              {!isPricingShow ? (
                <i className="ri-arrow-up-s-line text-24 font-clash-display font-bold" />
              ) : (
                <i className="ri-close-line text-24 font-clash-display font-bold" />
              )}
            </button>
          </div>
          {isPricingShow && (
            <div className="grid xl:grid-cols-5 lg:grid-cols-4 md:grid-cols-3 sm:grid-cols-2 gap-5 px-10 pb-5">
              {placesData?.pricing?.map((item, index) => (
                <div className="bg-primary1 grid grid-cols-3 gap-3 py-3 px-6 rounded-[24px] items-center">
                  <div className="col-span-1">
                    <img src={item?.img} width={56} height={56} />
                  </div>
                  <div className="col-span-2">
                    <div>
                      <span className="text-16 font-normal font-clash-display text-white">
                        {item?.name}
                      </span>
                    </div>
                    <div>
                      <span className="text-24 font-semibold font-clash-display text-white">
                        ₹ {item?.price}
                      </span>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          )}
        </div>
        <div className="mt-10">
          {placesData?.dataTrips?.map((place, index) => (
            <PlaceCard
              key={index}
              place={place.place}
              description={place.description}
              image={place.image}
              index={index}
              totalCount={placesData?.dataTrips?.length}
            />
          ))}
        </div>
      </div>

      <div className="mt-10">
        <PopularDestination />
      </div>
      <ThinkYouNeedTo />
      <Footer />
    </>
  );
};

export default PlaceInformation;
