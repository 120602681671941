import React from "react";
import CallUsIcon from "../assest/icons/callUsIcon.svg";
import WorthMoneyIcon from "../assest/icons/worthMoneyIcon.svg";
import ExitingTravelIcon from "../assest/icons/exitingTravelIcon.svg";

const ThinkYouNeedTo = () => {
  return (
    <div className="bg-secondary1 lg:grid lg:grid-cols-4 xl:px-[100px] lg:px-[40px] py-[30px]">
      <div className="col-span-1 text-white font-bold sm:text-48 text-32 font-clash-display px-10 three-d-text">
        <span className="three_d_text">Things you need to do</span>
      </div>
      <div className="col-span-3 grid md:grid-cols-3 sm:grid-cols-2 gap-3 lg:mt-0 mt-5">
        <div className="flex flex-col px-5">
          <img
            src={CallUsIcon}
            className="w-[46px] h-[46px] bg-secondary2 p-3 rounded-xl"
          />
          <span className="text-white sm:text-24 text-18 font-bold font-satoshi pt-3">
            Call Us
          </span>
          <span className="text-14 text-white font-normal font-satoshi pt-3">
            Questions about your travel plans? Call us now at{" "}
            <span className="text-16 text-neutral1 font-bold">9980399159</span>{" "}
            for expert assistance! Our dedicated team of travel experts is here
            to help you book your dream vacation or answer any inquiries you may
            have. With personalized service and expert guidance, we'll make your
            travel experience seamless and memorable. Your adventure awaits -
            contact us today!
          </span>
        </div>
        <div className="flex flex-col px-5">
          <img
            src={WorthMoneyIcon}
            className="w-[46px] h-[46px] bg-secondary2 p-3 rounded-xl"
          />

          <span className="text-white sm:text-24 text-18 font-bold font-satoshi pt-3">
            Worth of Money
          </span>
          <span className="text-14 text-white font-normal font-satoshi pt-3">
            Experience exceptional value for your money with our travel
            services! At{" "}
            <span className="text-16 text-neutral1 font-bold">
              Nature Sight Seeing
            </span>
            , we're committed to delivering unparalleled experiences without
            compromising on quality. With competitive prices, exclusive deals,
            and top-notch service, we ensure that every penny you spend with us
            goes towards creating unforgettable memories. Whether it's a
            budget-friendly getaway or a luxurious escape, we're dedicated to
            maximizing the worth of your investment in travel. Explore our range
            of offerings today and discover why we're your trusted partner for
            unforgettable journeys!
          </span>
        </div>
        <div className="flex flex-col px-5">
          <img
            src={ExitingTravelIcon}
            className="w-[46px] h-[46px] bg-secondary2 p-3 rounded-xl"
          />

          <span className="text-white sm:text-24 text-18 font-bold font-satoshi pt-3">
            Exiting Travel
          </span>
          <span className="text-14 text-white font-normal font-satoshi pt-3">
            Embark on an exhilarating journey with{" "}
            <span className="text-16 text-neutral1 font-bold">
              Nature Sight Seeing
            </span>
            ! From breathtaking landscapes to thrilling adventures, we
            specialize in curating unforgettable travel experiences that ignite
            your sense of adventure. Whether you're seeking adrenaline-pumping
            activities, cultural immersion, or simply a change of scenery, our
            expert team is here to craft the perfect itinerary tailored to your
            desires. Get ready to explore the world's most exciting destinations
            and create memories that will last a lifetime. Start your next
            adventure with us today!
          </span>
        </div>
      </div>
    </div>
  );
};

export default ThinkYouNeedTo;
