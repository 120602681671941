import MysoreImage from "./assest/images/mysore/mysuru-image.jpg";
import MysorePlaceImage from "./assest/images/mysore/mysorePalace.jpg";
import OotyImage from "./assest/images/ooty/ooty-Image.png";
import MadikeriImage from "./assest/images/madikariImage.jpg";
import CoorgImage from "./assest/images/ooty/coorg-image.png";
import MiniCarIcon from "./assest/icons/miniIcon.svg";
import SedanIcon from "./assest/icons/sedanIcon.svg";
import SUVIcon from "./assest/icons/suvIcon.svg";
import TTIcon from "./assest/icons/ttIcon.svg";
import BusIcon from "./assest/icons/busIcon.svg";
import MysoreZooImage from "./assest/images/mysore/mysoreZoo.jpg";
import ChamundiHillImage from "./assest/images/mysore/chamundiHill.jpg";
import WaxImage from "./assest/images/mysore/waxImage.jpg";
import SandMuseumImage from "./assest/images/mysore/Sand_museum_Mysore_sculpture.jpg";
import ChurchMyoreImage from "./assest/images/mysore/chruchMysore.jpg";
import SriRangaPatnaImage from "./assest/images/mysore/Sri_Ranganathaswamy.jpg";
import TippuDeathImage from "./assest/images/mysore/tipu-sultan-s-death-place.jpg";
import TippSummerImage from "./assest/images/mysore/tippuSummerImage.jpg";
import BrindavanImage from "./assest/images/mysore/brindavanGarden.jpg";
import MadahumalaiImage from "./assest/images/ooty/Mudumalai-National-Park-F.png"
import BandipurImage from "./assest/images/ooty/bandipur-national-park.jpg"
import NilgiriImage from "./assest/images/ooty/nilgiri-hill.jpg"
import KalhattiImage from "./assest/images/ooty/kalhatti-falls.jpg"
import OotyLakeImage from "./assest/images/ooty/ooty-lake.png"
import BotanicalImage from "./assest/images/ooty/botanical-garden.png"
import CoonorImage from "./assest/images/ooty/coonor.png"
import DolphinImage from "./assest/images/ooty/dolphins-nose.png"
import LambImage from "./assest/images/ooty/lamb-s-rock-view.jpg"
import TeaEstateImage from './assest/images/ooty/tea-estate.jpg'
import SimParkImage from "./assest/images/ooty/sims-park-coonoor.jpg"
import WellingtonIamge from "./assest/images/ooty/wellington.png"
import TeaFactoryImage from "./assest/images/ooty/tea-factory.jpg"
import DoddabettaImage from "./assest/images/ooty/doddabetta-view.png"
import PineTeaForestImage from "./assest/images/ooty/Pine-Tree-Forest.jpg"
import Mile16Image from "./assest/images/ooty/mile-16.jpg"
import ShootingImage from "./assest/images/ooty/shooting-spot.jpg"
import PykaraImage from "./assest/images/ooty/pykara-waterfalls.png"
import PykaraBackImage from "./assest/images/ooty/Pykara-backwaters.jpg"
import NeedleImage from "./assest/images/ooty/needle-rock.jpg"
import GoldenImage from "./assest/images/ooty/golden-temple.png"
import CauvaeryImage from "./assest/images/ooty/Cauvery-Nisargadhama.png"
import DubareImage from "./assest/images/ooty/Dubare-Elephant-Camp.jpg"
import ChikliholeImage from "./assest/images/ooty/Chiklihole-Reservoir.jpg"
import CoffePlantaionImage from "./assest/images/ooty/Coffee-Plantations.jpg"
import RajaseatImage from "./assest/images/ooty/Raja's_Seat_View_Point.jpg"
import TalaCauveryImage from "./assest/images/ooty/Talacauvery.jpg"
import BhagamandalaImage from "./assest/images/ooty/Bhagamandala.jpg"
import GlassBridgeImage from "./assest/images/ooty/Glass-Bridg.jpg"
import AbbeyFallImage from "./assest/images/ooty/Abbey-Falls.jpg"
import RajaTomImage from "./assest/images/ooty/Raja-Tomb.png"
import OmKareshwaraImage from "./assest/images/ooty/Omkareshwara-Temple.png"
import MandalapttiImage from "./assest/images/ooty/mandalpatti_Jeep_Ride_Safari.jpg"
import ArmyMuseumImage from "./assest/images/ooty/Army_Gallery.jpg"
import koteAbbeyFallImage from "./assest/images/ooty/Kote-Abbi.png"
import KotteTrekkingImage from "./assest/images/ooty/Kote-Betta-Trekking.jpg"
     

export const phoneNumber = 9980399159;

export const PlaceData = [
  {
    place: "Mysuru",
    description: `Mysuru, a cultural gem in southern India, enchants visitors with its rich history, architectural splendor, and vibrant cultural scene. Home to the magnificent Mysore Palace, Dasara festival celebrations, and traditional arts like Mysore silk weaving, the city exudes charm at every turn. From the tranquil beauty of Chamundi Hill to the bustling markets of Devaraja Urs Road, Mysuru captivates with its blend of ancient traditions and modern aspirations, making it a must-visit destination for travelers seeking a glimpse of India's cultural heritage.`,
    image: MysoreImage,
    startingPrice: 1000,
    dataTrips: [
      {
        place: "Mysore Palace",
        description: `Mysore Palace, also known as Amba Vilas Palace, is a historical and architectural marvel located in Mysore, Karnataka, India. It was originally constructed in the 14th century, but the current structure was built between 1897 and 1912 after the old palace was destroyed by fire. Designed by the British architect Henry Irwin, the palace blends Hindu, Muslim, Rajput, and Gothic architectural styles.`,
        image: MysorePlaceImage,
      },
      {
        place: "Mysore Zoo",
        description: `Mysore Zoo, officially known as Sri Chamarajendra Zoological Gardens, is one of the oldest and most well-maintained zoos in India. Established in 1892 by Maharaja Chamaraja Wodeyar, it spans over 157 acres and is located near Mysore Palace in Mysore, Karnataka.`,
        image: MysoreZooImage,
      },
      {
        place: "Chamundi Hills",
        description: `Chamundi Hill is a prominent landmark located about 13 kilometers from the city center of Mysore, Karnataka. Rising to an elevation of about 1,000 meters (3,300 feet), it is a popular pilgrimage site and tourist attraction.`,
        image: ChamundiHillImage,
      },
      {
        place: "Chamundeshwari Celebrity Wax Museum",
        description: `
        The Chamundeshwari Celebrity Wax Museum is a popular attraction located near Chamundi Hill in Mysore, Karnataka. This museum showcases life-size wax statues of famous personalities from various fields, including entertainment, politics, sports, and history. It is modeled after other famous wax museums like Madame Tussauds and aims to offer visitors a unique and interactive experience.`,
        image: WaxImage,
      },
      {
        place: "Mysore Sand Sculpture Museum",
        description: `The Mysore Sand Sculpture Museum is a unique and fascinating attraction located near Chamundi Hill in Mysore, Karnataka. It is the first of its kind in India and showcases an array of intricate sand sculptures crafted by sand artist M. N. Gowri.`,
        image: SandMuseumImage,
      },
      {
        place: "St. Philomena's Cathedral, Mysore",
        description: `St. Philomena's Cathedral, also known as St. Joseph's Cathedral, is a prominent landmark and one of the largest churches in India, located in Mysore, Karnataka. This majestic neo-Gothic style cathedral is dedicated to Saint Philomena, a 3rd-century Roman Catholic martyr.`,
        image: ChurchMyoreImage,
      },
      {
        place: "Srirangapatna",
        description: `Srirangapatna is home to several historical monuments and tourist attractions, including Tipu Sultan's Summer Palace, the Daria Daulat Bagh (Tipu Sultan's Palace), the Gumbaz (mausoleum of Tipu Sultan and his father Hyder Ali), and the Sri Ranganathaswamy Temple, dedicated to the Hindu god Vishnu.`,
        image: SriRangaPatnaImage,
      },
      {
        place: "Tipu Sultan's Death Place",
        description: `Tipu Sultan, the ruler of the Kingdom of Mysore, died during the Fourth Anglo-Mysore War in 1799. The place of his death was at the fortress of Seringapatam (present-day Srirangapatna), located near Mysore in the state of Karnataka, India.`,
        image: TippuDeathImage,
      },
      {
        place: "Tipu Sultan's Summer Palace",
        description: `Tipu Sultan's Summer Palace, also known as the Daria Daulat Bagh, is a historic monument located in Srirangapatna, near Mysore, Karnataka, India. It was built by Tipu Sultan, the ruler of the Kingdom of Mysore, as his summer retreat.`,
        image: TippSummerImage,
      },
      {
        place: "Brindavan Gardens",
        description: `Brindavan Gardens is a popular tourist destination and attracts thousands of visitors every year, especially during the weekends and holidays. The garden's serene ambiance, lush greenery, and captivating musical fountain show make it a favorite spot for families, couples, and nature enthusiasts.`,
        image: BrindavanImage,
      },
    ],
    pricing: [
      { img: MiniCarIcon, name: "Mini (4 Seater)", price: "2,000" },
      { img: SedanIcon, name: "Sedan (4 Seater)", price: "3,000" },
      { img: SUVIcon, name: "SUV (6,7 Seater)", price: "4,000" },
      { img: TTIcon, name: "TT (14 Seats)", price: "5,000" },
      { img: BusIcon, name: "Bus (52 Seats)", price: "10,000" },
    ],
  },
  {
    place: "Ooty",
    description: `Ooty, also known as Udhagamandalam, is a picturesque hill station nestled in the Nilgiri Hills, renowned for its cool climate, lush tea plantations, and scenic beauty. Key attractions include the expansive Ooty Lake, perfect for leisurely boating, and the Botanical Garden, which boasts a rich variety of plant species. The serene Nilgiri Hills offer breathtaking views and are dotted with charming spots like the Kalhatti Waterfalls. Visitors can also explore the vibrant flora and fauna at the nearby Bandipur National Park and Madhumalai Wildlife Sanctuary, making Ooty a perfect blend of natural splendor and tranquil retreats.`,
    image: OotyImage,
    startingPrice: 2000,
    dataTrips: [
      {
        place: "Bandipur National Park (Day 1)",
        description: `A renowned wildlife reserve, famous for its population of tigers, elephants, and other diverse flora and fauna. It's an essential spot for wildlife enthusiasts and nature lovers`,
        image: BandipurImage,
      },
      {
        place: "Madhumalai Wildlife Sanctuary (Day 1)",
        description: `Adjacent to Bandipur, this sanctuary is part of the Nilgiri Biosphere Reserve and is home to a variety of wildlife, including tigers, leopards, and birds. The sanctuary offers an immersive experience in nature.`,
        image: MadahumalaiImage,
      },
      {
        place: "Nilgiri Hills (Day 1)",
        description: `Known as the "Blue Mountains," these picturesque hills are covered with tea plantations, dense forests, and offer breathtaking views. The cool climate and stunning landscapes make it a popular tourist destination.`,
        image: NilgiriImage,
      },
      {
        place: "Kalhatti Waterfalls (Day 1)",
        description: `A beautiful cascade located on the Ooty-Mysore road. The waterfall is surrounded by lush greenery, making it a perfect spot for nature walks and picnics.`,
        image: KalhattiImage,
      },
      {
        place: "Ooty Lake (Day 1)",
        description: `An artificial lake that is a central attraction in Ooty. Visitors can enjoy boating, fishing, and the scenic beauty of the surrounding eucalyptus trees.`,
        image: OotyLakeImage,
      },
      {
        place: "Botanical Garden (Day 1)",
        description: `A well-maintained garden established in 1848, featuring a wide variety of plants, trees, and flowers. The garden is divided into several sections, including a fern house and a fossilized tree trunk that is over 20 million years old.`,
        image: BotanicalImage,
      },

      {
        place: "Coonoor (Day 2)",
        description: `A charming hill station near Ooty, known for its tea gardens and pleasant climate. It's quieter and less commercialized than Ooty, offering a peaceful retreat.`,
        image: CoonorImage,
      },
      {
        place: "Dolphin's Nose (Day 2)",
        description: `A unique rock formation resembling a dolphin's nose, offering panoramic views of the surrounding hills and valleys, including the Catherine Falls.`,
        image: DolphinImage,
      },
      {
        place: "Lamb's Rock Viewpoint (Day 2)",
        description: `Another stunning viewpoint in Coonoor, providing expansive views of the Coimbatore plains and the surrounding lush greenery.`,
        image: LambImage,
      },
      {
        place: "Tea Estate (Day 2",
        description: `The tea estates around Coonoor are renowned for their high-quality tea. Visitors can tour the plantations and learn about the tea-making process.`,
        image: TeaEstateImage,
      },
      {
        place: "Sim's Park (Day 2)",
        description: ` A well-maintained botanical garden in Coonoor, featuring a variety of plant species, including many rare and exotic ones. It's a great place for a leisurely stroll.`,
        image: SimParkImage,
      },
      {
        place: "Wellington (Day 2)",
        description: `A quaint town near Coonoor, known for the Defence Services Staff College and its serene environment. The Nilgiri Toy Train passes through this town, offering scenic views.`,
        image: WellingtonIamge,
      },
      {
        place: "Tea Factory (Day 2)",
        description: `Visit a tea factory to see the tea manufacturing process up close. Learn about the different stages of tea production and sample freshly brewed tea.`,
        image: TeaFactoryImage,
      },
      {
        place: "Doddabetta View Point (Day 2)",
        description: `The highest peak in the Nilgiri Hills, offering spectacular views of the surrounding area. It's a popular spot for trekking and enjoying the natural beauty of the region.`,
        image: DoddabettaImage,
      },
      {
        place: "Pine Tree Forest (Day 3)",
        description: `A picturesque forest of tall pine trees, often used as a filming location. The serene and cool atmosphere makes it a perfect place for walks and photography.`,
        image: PineTeaForestImage,
      },
      {
        place: "16th Mile (Day 3)",
        description: `A scenic spot known for its breathtaking views of the surrounding landscapes. It's a great place to stop and enjoy the beauty of the Nilgiris.`,
        image: Mile16Image,
      },
      {
        place: "Shooting Spot (Day 3)",
        description: `A popular filming location in the hills, known for its rolling meadows and stunning vistas. It's a favorite among photographers and nature lovers.`,
        image: ShootingImage,
      },
      {
        place: "Pykara Waterfalls (Day 3)",
        description: `A beautiful waterfall on the Pykara River, surrounded by dense forests. It's a popular spot for picnics and nature walks.`,
        image: PykaraImage,
      },
      {
        place: "Pykara Backwaters (Day 3)",
        description: `The backwaters of the Pykara Dam offer tranquil boat rides amidst serene surroundings, making it a relaxing experience for visitors.`,
        image: PykaraBackImage,
      },
      {
        place: "Needle Rock Viewpoint (Day 3)",
        description: `Named for its shape, this viewpoint offers stunning panoramic views of the surrounding hills and valleys. It's an ideal spot for photography and enjoying the natural beauty.`,
        image: NeedleImage,
      },
    ],
    pricing: [
      { img: MiniCarIcon, name: "Mini (4 Seater)", price: "2,000" },
      { img: SedanIcon, name: "Sedan (4 Seater)", price: "3,000" },
      { img: SUVIcon, name: "SUV (6,7 Seater)", price: "4,000" },
      { img: TTIcon, name: "TT (14 Seats)", price: "5,000" },
      { img: BusIcon, name: "Bus (52 Seats)", price: "10,000" },
    ],
  },
  {
    place: "Coorg",
    description: `
    Coorg, also known as Kodagu, is a picturesque hill station in Karnataka, famed for its lush coffee plantations, scenic landscapes, and rich cultural heritage. The region offers numerous attractions, such as the Abbey Falls, a stunning waterfall set amidst coffee and spice plantations, and the Raja's Seat, a beautiful garden with panoramic views of the misty hills. Visitors can explore historical sites like the Madikeri Fort and Raja’s Tomb, and enjoy nature at the Dubare Elephant Camp, where they can interact with elephants. Coorg is also home to spiritual sites like the Talacauvery, the source of the River Cauvery, and the Golden Temple (Namdroling Monastery), a major Tibetan Buddhist monastery. The serene environment, combined with adventure activities like trekking at Tadiandamol Peak and jeep safaris at Mandalpatti, makes Coorg a perfect destination for nature lovers and adventure enthusiasts alike.`,
    image: CoorgImage,
    startingPrice: 3000,
    dataTrips: [
      {
        place: "Golden Temple (Day 1)",
        description: ` Located in Bylakuppe, this Tibetan Buddhist monastery is one of the largest teaching centers of the Nyingma lineage. Its stunning architecture, golden statues, and serene ambiance make it a must-visit.`,
        image: GoldenImage,
      },
      {
        place: "Cauvery Nisargadhama (Day 1)",
        description: `An island formed by the River Cauvery, this picturesque spot offers lush bamboo groves, sandalwood trees, and wildlife like deer and peacocks. Visitors can enjoy boating, elephant rides, and nature walks.`,
        image: CauvaeryImage,
      },
      {
        place: "Dubare Elephant Camp (Day 1)",
        description: `A popular attraction where visitors can interact with elephants. You can watch them being bathed and fed, and even take a ride on these gentle giants. The camp also offers river rafting and bird watching.`,
        image: DubareImage,
      },
      {
        place: "Chiklihole Reservoir (Day 1)",
        description: `serene spot surrounded by dense forests, ideal for picnics and nature walks. The reservoir is a great place to enjoy the tranquility and beauty of Coorg's natural landscape.`,
        image: ChikliholeImage,
      },
      {
        place: "Coffee Plantations (Day 1)",
        description: `Coorg is famous for its coffee. Touring a coffee plantation, you can learn about the coffee-making process from bean to cup, and savor freshly brewed coffee amidst lush greenery.`,
        image: CoffePlantaionImage,
      },
      {
        place: "Raja's Seat (Day 1)",
        description: `A scenic viewpoint offering breathtaking views of the mist-covered hills and valleys. Historically, it was the favorite spot of the Kodagu kings to watch sunsets and enjoy the panoramic vistas.`,
        image: RajaseatImage,
      },
      {
        place: "Talacauvery (Day 2)",
        description: `The source of the River Cauvery, located on the Brahmagiri Hill. It's a sacred spot for Hindus and offers stunning views of the surrounding hills. The temple at Talacauvery is an important pilgrimage site.`,
        image: TalaCauveryImage,
      },
      {
        place: "Bhagamandala (Day 2)",
        description: `A pilgrimage site at the confluence of three rivers—Cauvery, Kannike, and Sujyoti. The Bhagandeshwara Temple, dedicated to Lord Shiva, is a significant religious site.`,
        image: BhagamandalaImage,
      },
      {
        place: "Glass Bridge (Day 2)",
        description: `A modern attraction offering a thrilling experience of walking on a transparent bridge while enjoying panoramic views of the lush landscape below.`,
        image: GlassBridgeImage,
      },
      {
        place: "Abbey Falls (Day 2)",
        description: `A picturesque waterfall located amidst coffee plantations and spice estates. The roar of the cascading water and the surrounding greenery make it a popular tourist spot.`,
        image: AbbeyFallImage,
      },
      {
        place: "Raja's Tomb (Gaddige) (Day 2)",
        description: `The royal tombs of Kodagu kings, showcasing Indo-Saracenic architecture. The beautifully maintained garden and the tombs offer a glimpse into the region's history.`,
        image: RajaTomImage,
      },
      {
        place: "Omkareshwara Temple (Day 2)",
        description: `A unique temple built in a blend of Gothic and Islamic styles. Dedicated to Lord Shiva, the temple features a central dome and four minarets, and it holds a significant place in Coorg's religious heritage.`,
        image: OmKareshwaraImage,
      },
      {
        place: "Mandalpatti Jeep Safari (Day 3)",
        description: `An adventurous ride to the Mandalpatti viewpoint, offering stunning views of the Western Ghats. The off-road journey and the panoramic vistas make it a thrilling experience.`,
        image: MandalapttiImage,
      },
      {
        place: "Madikeri Fort (Day 3)",
        description: `A historic fort offering insights into Coorg's history. It houses a museum, a chapel, and offers beautiful views of the surrounding area. The fort reflects the region's colonial past and local heritage.`,
        image: MadikeriImage,
      },
      {
        place: "Army Museum (Day 3)",
        description: `Located in Madikeri, this museum showcases the history and achievements of the Indian Army, particularly the Madras Regiment. It's a place of pride and valor, offering a glimpse into military life.`,
        image: ArmyMuseumImage,
      },
      {
        place: "Kote Abbey Falls (Day 3)",
        description: `Another beautiful waterfall near Madikeri, known for its serene surroundings and less crowded ambiance. It's an ideal spot for nature lovers and photographers.`,
        image: koteAbbeyFallImage,
      },
      {
        place: "Kote Betta Trekking (Day 3)",
        description: `A popular trekking destination offering a challenging yet rewarding experience. The trek leads to Kote Betta peak, one of the highest peaks in Coorg, providing stunning views of the landscape.`,
        image: KotteTrekkingImage,
      },

    ],
    pricing: [
      { img: MiniCarIcon, name: "Mini (4 Seater)", price: "2,000" },
      { img: SedanIcon, name: "Sedan (4 Seater)", price: "3,000" },
      { img: SUVIcon, name: "SUV (6,7 Seater)", price: "4,000" },
      { img: TTIcon, name: "TT (14 Seats)", price: "5,000" },
      { img: BusIcon, name: "Bus (52 Seats)", price: "10,000" },
    ],
  },

];
