import React from "react";
import "./components.css";

const PlaceCard = ({ place, description, image, index, totalCount }) => {
  return (
    <React.Fragment key={index}>
      <div className="xl:block hidden xl:flex justify-between items-center">
        {index % 2 === 0 ? (
          // Render right side for even index
          <>
            <div className="w-[47%] flex justify-end">
              <div className="pe-5 ps-10 text-right">
                <span className="text-24 font-bold font-satoshi text-secondary1">
                  {place}
                </span>
                <div>
                  <span
                    className="font-normal text-18 font-satoshi text-neutral1"
                    title={description}
                  >
                    {description?.substring(0, 250)}
                    {description?.length > 250 && "..."}
                  </span>
                </div>
              </div>
              <img
                src={image}
                alt={place}
                width={250}
                height={250}
                className="md:rounded-[20px]"
              />
            </div>
            <div className="flex justify-center relative">
              <div className="absolute p-2 border border-dashed rounded-full border-neutral2 bg-white">
                <div
                  className={`w-[20px] h-[20px] rounded-full bg-neutral2`}
                ></div>
              </div>
              <div
                className={
                  totalCount - 1 === index
                    ? `h-[250px] w-[2px] bg-inherit`
                    : `h-[250px] w-[2px] bg-neutral2`
                }
              ></div>
            </div>
            <div className="w-[47%]"></div>
          </>
        ) : (
          // Render left side for odd index
          <>
            <div className="w-[47%]"></div>
            <div className="flex justify-center relative">
              <div className="absolute p-2 border border-dashed rounded-full border-neutral2 bg-white">
                <div className="w-[20px] h-[20px] rounded-full bg-neutral2 "></div>
              </div>
              <div
                className={
                  totalCount - 1 === index
                    ? `h-[250px] w-[2px] bg-inherit`
                    : `h-[250px] w-[2px] bg-neutral2`
                }
              ></div>
            </div>
            <div className="w-[47%] flex">
              <img
                src={image}
                alt={place}
                width={250}
                height={250}
                className="md:rounded-[20px]"
              />
              <div className="pe-10 ps-5 text-left">
                <span className="text-24 font-bold font-satoshi text-secondary1">
                  {place}
                </span>
                <div>
                  <span
                    className="font-normal text-18 font-satoshi text-neutral1"
                    title={description}
                  >
                    {description?.substring(0, 250)}
                    {description?.length > 250 && "..."}
                  </span>
                </div>
              </div>
            </div>
          </>
        )}
      </div>

      <div className="xl:hidden block py-5 md:px-10">
        <div className="md:grid md:grid-cols-4">
          <div className="md:col-span-1 flex justify-center">
            <img
              src={image}
              alt={place}
              width={"100%"}
              height={250}
              className="md:rounded-[20px]"
            />
          </div>
          <div className="pe-10 ps-5 text-left col-span-3 md:pt-0 pt-3">
            <span className="text-24 font-bold font-satoshi text-secondary1">
              {place}
            </span>
            <div>
              <span className="font-normal text-18 font-satoshi text-neutral1">
                {description}
              </span>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default PlaceCard;
